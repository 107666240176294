import { createMuiTheme, ThemeOptions } from '@material-ui/core';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#0d6efd',
    },
    secondary: {
      main: '#D9138A',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.9em",
      }
    }
  }
}

export const themeDefault = createMuiTheme( themeOptions );

export const themeDefaultDark = createMuiTheme({
  ...themeOptions,
  palette: {
    type: 'dark',
    ...themeOptions.palette
  },
});
