import * as React from "react"
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from "gatsby-theme-i18n"

const HeaderMenuFull: React.FC = () => {
  const {t} = useTranslation("header")

  return (
    <ul className="menu-options-items">
      <li className="menu-options-items-item">
        <LocalizedLink to="/">{t('home')}</LocalizedLink>
      </li>
      <li className="menu-options-items-item">
        <LocalizedLink to="/about">{t('about')}</LocalizedLink>
      </li>
      <li className="menu-options-items-item">
        <LocalizedLink to="/contact">{t('contact')}</LocalizedLink>
      </li>
    </ul>
  );
}

export default HeaderMenuFull
