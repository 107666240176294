import * as React from 'react';
import { GetPostQuery } from '../../graphql-types';
import { PostPageContext } from '../page-contexts/post-page-context';


export type PostType = GetPostQuery['mdx'];
export type PostContextType = {
  post?: PostType;
  setPost: (post: PostType) => void;
  postContext?: PostPageContext;
  setPostContext?: (postContext: PostPageContext) => void;
}

export const PostContext = React.createContext<PostContextType>({
  post: null,
  setPost: () => console.warn('No provider for postContext!'),
  postContext: null,
  setPostContext: () => console.warn('No provider for postContext!'),
});

export const PostContextProvider = (defaultPost?: PostType, defaultPostContext?: PostPageContext): PostContextType => {

  const [post, setPost] = React.useState<PostType>(defaultPost);
  const [postContext, setPostContext] = React.useState<PostPageContext>(defaultPostContext);

  const setCurrentPost = (post: PostType) => {
    // console.log('setPost', post);
    setPost(post);
  }

  const setCurrentPostContext = (postContext: PostPageContext) => {
    // console.log('setCurrentPostContext', postContext);
    setPostContext(postContext);
  }

  return {
    post,
    setPost: setCurrentPost,
    postContext,
    setPostContext: setCurrentPostContext,
  };
};
