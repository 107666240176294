import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MdxLink } from 'gatsby-theme-i18n';

import { makeStyles } from '@material-ui/core';

const components = {
  a: MdxLink,
}

const useStyles = makeStyles({
  main: {
    flexGrow: 1,
    width: '100%',
    marginTop: 'var(--header-height)',
    marginBottom: '1em'
  }
});

const Main: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <MDXProvider components={components}>
        {children}
      </MDXProvider>
    </main>
  );
}

export default Main;
