import * as React from "react"
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { PostContext } from '../contexts/post.context';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    width: '100%',
    top: 'calc(var(--header-height) - 4px)'
  }
});

const HeaderProgress: React.FC = () => {

  const classes = useStyles();
  const postContext = React.useContext(PostContext);
  const [percent, setPercent] = React.useState(0);

  let postBody: HTMLElement;
  const calculateProgress = () => {
    const percent = (window.scrollY + 1) / ( postBody.getBoundingClientRect().height - postBody.offsetTop * 2);
    setPercent(Math.min(percent * 100, 100) );
  }

  const onScroll = () => {
    requestAnimationFrame( calculateProgress );
  }

  const onIntersection = (entries: IntersectionObserverEntry[]) => {
    const entry = entries?.[0];
    if ( entry.isIntersecting ) {
      calculateProgress();
      window.addEventListener('scroll', onScroll);
    } else {
      window.removeEventListener('scroll', onScroll);
    }
  }

  useEffect(() => {
    postBody = document.getElementsByClassName('post-body')?.[0] as HTMLElement;

    let observer: IntersectionObserver;
    if ( postBody ) {
      observer = new IntersectionObserver(onIntersection);
      observer.observe(postBody);
    }

    return () => {
      observer?.disconnect();
      window.removeEventListener('scroll', onScroll);
    }
  }, [ postContext.post ])

  return (
    <div className={classes.container}>
      {
        postContext.post && (
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={percent} />
        )
      }
    </div>
  );
}

export default HeaderProgress
