import * as React from "react"
import { navigate } from 'gatsby';
import { LocaleContext } from "gatsby-theme-i18n"
import { FaGlobeAmericas } from '@react-icons/all-files/fa/FaGlobeAmericas';

import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { PostContext } from '../contexts/post.context';


const useStyles = makeStyles({
  text: {
    marginLeft: '5px',
    verticalAlign: 'text-top'
  },
  button: {
    color: 'white'
  }
});

const LanguageSwitcher: React.FC = () => {

  const {t} = useTranslation('header');
  const classes = useStyles();

  const postContext = React.useContext(PostContext);

  const currentLanguage = React.useContext(LocaleContext)
  const nextLanguage = currentLanguage === 'pt' ? 'English' : 'Português';

  const changeLanguage = () => {
    const otherUrls = postContext?.postContext?.otherUrls?.filter(x => x.locale !== currentLanguage);
    if (otherUrls?.length) {
      const otherUrl = otherUrls?.[0];
      let url = '/' + otherUrl?.locale + otherUrl?.slug;
      return navigate(url);
    }

    const urls = location.pathname.split('/');
    if (urls?.length) {
      urls[1] = currentLanguage === 'pt' ? 'en' : 'pt'
    }
    return navigate(urls.join('/'));
  }

  return (
    <Tooltip title={t('changeLanguage')} arrow>
      <Button onClick={changeLanguage} className={classes.button}>
        <FaGlobeAmericas/>
        <span className={classes.text}>
          {nextLanguage}
        </span>
      </Button>
    </Tooltip>
  );
}

export default LanguageSwitcher
