import * as React from "react"
import { useMediaQuery, useTheme } from '@material-ui/core';

import "../styles/header.scss";

import LanguageSwitcher from './language-switcher';
import DarkModeSwitcher from './dark-mode-switcher';
import HeaderMenuFull from './header-menu-full';
import HeaderMenuMin from './header-menu-min';
import HeaderProgress from './header-progress';

const Header: React.FC = () => {
  const theme = useTheme();
  const showMenuFullMenu = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <header className="site-header">
      <nav className="container">

        <div className="menu-start">
          {/*<LocalizedLink to="/">Higor Cavalcanti</LocalizedLink>*/}
        </div>

        <div className="menu-options">
          { showMenuFullMenu ? (<HeaderMenuFull/>) : (<HeaderMenuMin/>) }
        </div>

        <div className="menu-end">
          <LanguageSwitcher/>
          <DarkModeSwitcher/>
        </div>

      </nav>
      <HeaderProgress/>
    </header>
  );
}

export default Header
