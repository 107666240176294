import * as React from "react"

const LayoutContainer: React.FC = ({ children }) => {
  return (
    <section className="container">
      {children}
    </section>
  );
}

export default LayoutContainer;
