import * as React from "react"

import Header from "./header";
import Footer from "./footer";
import Main from './main';

import { makeStyles, ThemeProvider } from "@material-ui/core";

import { themeDefault, themeDefaultDark } from '../theme/default-theme';

import { DarkModeContext, DarkModeProvider } from '../contexts/dark-mode.context';
import ScrollToTop from './scroll-to-top';


const useStyles = makeStyles({
  site: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }
});

const Layout: React.FC = ({children}) => {

  const darkMode = DarkModeProvider();

  const classes = useStyles();

  return (
    <DarkModeContext.Provider value={darkMode}>
      <ThemeProvider theme={darkMode.enabled ? themeDefaultDark : themeDefault}>
        <div className={classes.site}>
          <Header/>
          <Main>{children}</Main>
          <Footer/>
          <ScrollToTop/>
        </div>
      </ThemeProvider>
    </DarkModeContext.Provider>
  )
}

export default Layout;
