import * as React from "react"
import { FaSun } from '@react-icons/all-files/fa/FaSun';
import { FaMoon } from '@react-icons/all-files/fa/FaMoon'
import { useTranslation } from 'react-i18next';

import { useDarkMode } from '../contexts/dark-mode.context';
import { Button, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    minWidth: '1em',
    fontSize: '1.05em',
    color: 'white'
  }
});

const DarkModeSwitcher: React.FC = () => {
  const { t } = useTranslation("header");
  const darkMode = useDarkMode();
  const classes = useStyles();

  return (
    <Tooltip title={t(darkMode.enabled ? 'disableDarkMode' : 'enableDarkMode')} arrow>
      <Button onClick={() => darkMode.toggleDarkMode()} className={classes.button}>
        { darkMode.enabled ? (<FaSun/>) : (<FaMoon/>) }
      </Button>
    </Tooltip>
  );
}
export default DarkModeSwitcher
