import * as React from "react"
import { useTranslation } from 'react-i18next';
import { LocaleContext } from "gatsby-theme-i18n"
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Button, makeStyles } from '@material-ui/core';
import { navigate } from 'gatsby';

import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { FaInfo } from '@react-icons/all-files/fa/FaInfo';
import { FaCommentDots } from '@react-icons/all-files/fa/FaCommentDots';


interface MenuItem {
  icon: JSX.Element;
  label: string;
  link: string;
}

const useStyles = makeStyles({
  presentation: {
    width: '250px'
  }
});

const HeaderMenuMin: React.FC = () => {
  const {t} = useTranslation("header");
  const locale = React.useContext(LocaleContext);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemClick = (item: MenuItem) => {
    return navigate(`/${locale}${item.link}`);
  }

  const items: MenuItem[] = [
    { icon: <FaHome/>, label: t('home'), link: '/' },
    { icon: <FaInfo/>, label: t('about'), link: '/about' },
    { icon: <FaCommentDots/>, label: t('contact'), link: '/contact' }
  ];

  return (
    <React.Fragment>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ color: 'white' }}>
        <FaBars/>
      </Button>

      <Drawer anchor="left" open={open} onClose={handleClose}>
        <div role="presentation" className={classes.presentation}>
          <List>
            { items.map(item => (
              <React.Fragment key={item.label}>
                <ListItem button onClick={() => handleItemClick(item)}>
                  <ListItemIcon>{ item.icon }</ListItemIcon>
                  <ListItemText>{ item.label }</ListItemText>
                </ListItem>
              </React.Fragment>
            )) }
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default HeaderMenuMin
