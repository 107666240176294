import { createContext, useContext } from 'react';
import {
  disableDarkMode,
  enableDarkMode,
  isDarkModeEnabled,
  toggleDarkMode as themeToggleDarkMode
} from '../utils/dark-mode';
import * as React from 'react';

export type DarkModeContextType = {
  enabled: boolean;
  setDarkMode: (darkMode: boolean) => void;
  toggleDarkMode: () => void;
}

export const DarkModeContext = createContext<DarkModeContextType>({
  enabled: true,
  setDarkMode: (_enabled: boolean) => console.warn('No provider'),
  toggleDarkMode: () => console.warn('No provider')
});

export const DarkModeProvider = () => {
  const [enabled, setEnabled] = React.useState( isDarkModeEnabled() );

  const calculateIsEnabled = () => {
    setEnabled( isDarkModeEnabled() );
  }

  const setDarkMode = (enabled: boolean) => {
    if ( enabled ) {
      enableDarkMode();
    } else {
      disableDarkMode();
    }
    calculateIsEnabled();
  }

  const toggleDarkMode = () => {
    themeToggleDarkMode();
    calculateIsEnabled();
  }

  return {
    enabled,
    setDarkMode,
    toggleDarkMode
  };
};

export const useDarkMode = () => useContext(DarkModeContext);
