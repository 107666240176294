import * as React from "react"
import { Fab, makeStyles, Slide, Tooltip } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';
import { useEffect } from 'react';

const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    bottom: '1em',
    right: '1em'
  },
});

const ScrollToTop: React.FC = () => {

  const { t } = useTranslation("footer");
  const classes = useStyles();

  const [show, setShow] = React.useState(false);

  const onScroll = () => {
    requestAnimationFrame(() => {
      setShow(window.scrollY > 200);
    });
  }

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  })

  return (
    <Slide in={show} direction="up" mountOnEnter unmountOnExit>
      <Tooltip title={t('backToTop')} arrow>
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={backToTop}>
          <FaChevronUp/>
        </Fab>
      </Tooltip>
    </Slide>
  );
}
export default ScrollToTop;
