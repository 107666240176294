import * as React from "react"
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FaHeart } from '@react-icons/all-files/fa/FaHeart';

const useStyles = makeStyles({
  footer: {
    background: 'black',
    color: 'white',
    textAlign: 'center',
    height: '50px',
    lineHeight: '50px',
  },
  svg: {
    height: '0.9rem',
    color: 'red',
    margin: '0 0.25em',
  }
});

const Footer: React.FC = () => {

  const { t } = useTranslation("footer");
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      {t("madeWith1")}
      &nbsp;
      <FaHeart className={classes.svg}/>
      &nbsp;
      {t("madeWith2")}
      &nbsp;
      <a href="https://www.gatsbyjs.com" target="_blank">
        Gatsby
      </a>
    </footer>
  );
}
export default Footer;
